import revive_payload_client_dPJX965HJc from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_5ymrz4hpn4ppthf6tdkhn75f2i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5Ev2IPSPiU from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_5ymrz4hpn4ppthf6tdkhn75f2i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Fxo6H35pBh from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_5ymrz4hpn4ppthf6tdkhn75f2i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_cTeoZ4Ef8b from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_5ymrz4hpn4ppthf6tdkhn75f2i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vSSV5DbvMW from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_5ymrz4hpn4ppthf6tdkhn75f2i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5gZRraTWBD from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_5ymrz4hpn4ppthf6tdkhn75f2i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lwlzvfKMbH from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_5ymrz4hpn4ppthf6tdkhn75f2i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_IuqO456aVX from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@4.18.1_typescript@5.5.4_vue@3.4.37_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_DR1TjzE4rY from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_5ymrz4hpn4ppthf6tdkhn75f2i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/render/project/src/.nuxt/primevue-plugin.mjs";
import plugin_client_qqdUN3aNa2 from "/opt/render/project/src/node_modules/.pnpm/@primevue+nuxt-module@4.0.4_patch_hash=wiemqw53ii2cuxy56mkzosavte_@babel+parser@7.25.3_magica_eziykbk75nlxhlgt6t4dv5kk24/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import _01_directus_80HGRry5qP from "/opt/render/project/src/plugins/01.directus.ts";
import _02_refreshToken_WDp7SsBhVi from "/opt/render/project/src/plugins/02.refreshToken.ts";
import _03_configOverrides_GceWKGsitr from "/opt/render/project/src/plugins/03.configOverrides.ts";
import _04_theme_pZjsSCyjPE from "/opt/render/project/src/plugins/04.theme.ts";
export default [
  revive_payload_client_dPJX965HJc,
  unhead_5Ev2IPSPiU,
  router_Fxo6H35pBh,
  payload_client_cTeoZ4Ef8b,
  navigation_repaint_client_vSSV5DbvMW,
  check_outdated_build_client_5gZRraTWBD,
  chunk_reload_client_lwlzvfKMbH,
  plugin_vue3_IuqO456aVX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DR1TjzE4rY,
  primevue_plugin_egKpok8Auk,
  plugin_client_qqdUN3aNa2,
  _01_directus_80HGRry5qP,
  _02_refreshToken_WDp7SsBhVi,
  _03_configOverrides_GceWKGsitr,
  _04_theme_pZjsSCyjPE
]